export const sectors: Record<string, string> = {
  '0': 'None',
  '10': 'Agriculture, Horticulture & Livestock',
  '11': 'Mining & Extraction',
  '12': 'Utilities',
  '13': 'Construction',
  '14': 'Food & Tobacco Manufacturing',
  '15': 'Textiles & Clothing Manufacturing',
  '16': 'Wood, Furniture & Paper Manufacturing',
  '17': 'Printing & Publishing',
  '18': 'Chemicals, Petroleum, Rubber & Plastic',
  '19': 'Leather, Stone, Clay & Glass products',
  '20': 'Metals & Metal Products',
  '21': 'Industrial, Electric & Electronic Machinery',
  '22': 'Computer Hardware',
  '23': 'Communications',
  '24': 'Transport Manufacturing',
  '25': 'Miscellaneous Manufacturing',
  '26': 'Wholesale',
  '27': 'Retail',
  '28': 'Transport, Freight & Storage',
  '29': 'Travel, Personal & Leisure',
  '30': 'Computer Software',
  '31': 'Media & Broadcasting',
  '32': 'Banking, Insurance & Financial Services',
  '33': 'Property Services',
  '34': 'Business Services',
  '35': 'Biotechnology and Life Sciences',
  '36': 'Information Services',
  '37': 'Public Administration, Education, Health Social Services',
  '38': 'Waste Management & Treatment',
};
