/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import _ from 'lodash';

import ADSCountries from '../../lib/data/ads_countries.json';
import countryCodeJSON from '../../lib/data/countryCodes.json';
import localisationJSON from '../../lib/data/localisation.json';
import { sectors } from '../utils/sector-codes';
import { createCurrencyString } from '../utils/text-helpers';
import { invalidCurrencyCodes } from './sme-calc.settings';

const supportedLocales = localisationJSON.map(value => {
  return { optionValue: value.code, optionName: value.locale };
});

const supportedCountries = countryCodeJSON
  .filter(c => !invalidCurrencyCodes.includes(c.currency_code))
  .map(country => ({
    optionName: country.name,
    optionValue: country.code,
    code: country.code,
  }));

// eslint-disable-next-line sonarjs/no-identical-functions
const supportedADSCountries = ADSCountries.map(country => ({
  optionName: country.name,
  optionValue: country.code,
  code: country.code,
}));

const supportedCurrenciesByCountry = countryCodeJSON
  .filter(c => !invalidCurrencyCodes.includes(c.currency_code))
  .map(country => ({
    optionName: createCurrencyString({
      name: country.name,
      currency_symbol: country.currency_symbol,
      currency_code: country.currency_code,
    }),
    optionValue: country.name,
    code: country.currency_code,
  }));

export const getCurrencyByIsoCode = (iso_code: string, defaultCode = 'GBP') => {
  const currency_code = countryCodeJSON.find(
    country => country.code === iso_code
  )?.currency_code;

  if (currency_code) return currency_code;

  return defaultCode;
};

/**
 * used to set the currency as an option when provided with an iso_code
 * see implementation below
 */
const getCurrencyOptionByIsoCode = (iso_code: string) => {
  // get country by iso_code from countryCodeJSON
  const country = countryCodeJSON.find(country => country.code === iso_code);
  // turn that country into an option
  if (country) {
    const option = {
      optionName: createCurrencyString({
        name: country.name,
        currency_symbol: country.currency_symbol,
        currency_code: country.currency_code,
      }),
      optionValue: country.name,
      code: country.currency_code,
    };
    return option;
  }
};

export const filterCountriesByIsoCodes = (iso_codes: string[]) => {
  const filteredCountries = countryCodeJSON
    .filter(country => iso_codes.includes(country.code))
    .map(country => country.name);
  return filteredCountries;
};

export const getCountryFromIsoCode = (
  iso_code?: string,
  truncate?: number
): string => {
  const country = countryCodeJSON.find(country => country.code === iso_code);

  if (truncate) {
    return country
      ? _.truncate(country.name, {
          length: truncate,
        })
      : '';
  }

  return country?.name ?? '';
};

export const getSectorBySectorCode = (
  sector_code?: number,
  truncate?: number
) => {
  const sector = sectors[`${sector_code}`] || '';

  if (truncate) {
    return sector
      ? _.truncate(sector, {
          length: truncate,
        })
      : '';
  }

  return sector;
};

const SettingsSettings = {
  dashboardOptionValues: [
    'dashboard',
    'reports',
    'single_report',
    'multiple_reports',
  ],
  preferences: {
    defaults: {
      locale: 'en-GB',
      currency: 'United Kingdom',
      home_page: 'dashboard',
      reporting_country: 'GB',
    },
    communication: {
      batch_report_email: true,
      service_updates: true,
      company_updates: false,
    },
  },
  supportedLocales,
  supportedADSCountries,
  supportedCountries,
  supportedCurrenciesByCountry,
  getCurrencyByIsoCode,
  getCurrencyOptionByIsoCode,
  getCountryFromIsoCode,
};

export default SettingsSettings;

/**
 * implementation of getCurrencyOptionByIsoCode
 */

// useEffect(() => {
//   if (uploadReportType.iso_code) {
//     const autoBatchCurrency = Settings.getCurrencyOptionByIsoCode(
//       uploadReportType.iso_code
//     );

//     if (autoBatchCurrency) {
//       setCurrency(autoBatchCurrency);
//     }
//   }
// }, [uploadReportType.iso_code]);
